﻿
.tile-field-content {
    font-size: $typo-font-std;
    font-weight: $typo-font-weight-bold;    
}

.tile-field-label {
    font-size: $typo-font-small;
    line-height: $global-lh-small;
}

.tile-text-small {
    font-size: $typo-font-small;   
}

/*.tile-hr {
    margin-left: 0;
    margin-right: 0;
}*/



