.btn-group {
    position: relative;
    display: inline-flex;

    > .btn, button {
        position: relative;
        flex: 1 1 auto;

        &.-small-width, &.-medium-width, &.-large-width, &.-larger-width, &.-huge-width {
            flex-grow: 0;
        }
    }

    > .btn:not(:first-child), button:not(:first-child) {
        margin-left: -$global-border-small;
    }

    > .btn:not(:first-child):not(:last-child), button:not(:first-child):not(:last-child) {
        border-radius: 0;
    }

    > .btn:first-child:last-child, button:first-child:last-child {
        border-radius: $layout-radius-small $layout-radius-small $layout-radius-small $layout-radius-small;
    }

    > .btn:first-child, button:first-child {
        border-radius: $layout-radius-small 0 0 $layout-radius-small;
    }

    > .btn:last-child, button:last-child {
        border-radius: 0 $layout-radius-small $layout-radius-small 0;
    }

    &.-full-width {
        width: 100%;
    }

    // make sure tertiary button border is fully visible on hover
    > .btn.-tertiary:hover, button.-tertiary:hover {
        z-index: $global-z-hover-layer;
    }
}
