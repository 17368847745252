﻿h1, h2, h3, h4, h5, h6,
.h1-headline, .h2-title, .h3-subheader, .h4-menu {
    font-family: #{$typo-font-title};
    font-weight: #{$typo-font-weight-regular};
    font-style: normal;
    margin: #{$layout-space-medium} 0;
    line-height: #{$global-lh-medium};
    letter-spacing: #{$global-ls-small};
}

h1, .h1-headline {
    font-size: #{$typo-font-huge};
    margin: 0 0 10px;
}

h2, .h2-title {
    font-size: #{$typo-font-largest};
    font-weight: #{$typo-font-weight-medium};
}

h3, .h3-subheader {
    font-size: #{$typo-font-larger};
}

h4, .h4-menu {
    font-size: #{$typo-font-large};
    font-weight: #{$typo-font-weight-medium};
}

h5 {
    font-size: #{$typo-font-std};
    font-weight: #{$typo-font-weight-bold};
}

h6 {
    font-size: #{$typo-font-std};
    font-weight: #{$typo-font-weight-medium};
}

ul {
    margin: 0;
    padding-left: 15px;
}

p {
    margin-bottom: #{$layout-space-medium};
}

a {
    text-decoration: underline;
    color: var(--primary-text-color);

    &:hover, &:active {
        color: var(--primary-text-color);
    }
}

em {
    font-style: italic;
}

hr {
    height: 0.1rem;
    background-color:var(--text-color);
    border:none;
    margin:1rem;
}

code {
    padding: #{$layout-space-smallest} #{$layout-space-small};
    font-size: 90%;
    color: #c7254e;
    background-color: var(--field-background);
    border-radius: #{$layout-radius-small};
}

b, .bold {
    font-weight: #{$typo-font-weight-bold}!important;
}

.text-regular {
    font-weight: #{$typo-font-weight-regular}!important;
}

._text-giant {
    font-size: #{$typo-font-giant}!important;
};

._text-huge {
    font-size: #{$typo-font-huge}!important;
};

._text-largest {
    font-size: #{$typo-font-largest}!important;
};

._text-larger {
    font-size: #{$typo-font-larger}!important;
};

._text-large {
    font-size: #{$typo-font-large}!important;
};

._text-medium {
    font-size: #{$typo-font-medium}!important;
};

._text-std {
    font-size: #{$typo-font-std}!important;
};

._text-small {
    font-size: #{$typo-font-small}!important;
};

._text-smaller {
    font-size: #{$typo-font-smaller}!important;
};

._text-smallest {
    font-size: #{$typo-font-smallest}!important;
};

._text-icon {
    font-size: #{$typo-font-icon}!important;
};

._text-icon-small {
    font-size: #{$typo-font-icon-small}!important;
};

._text-icon-large {
    font-size: #{$typo-font-icon-large}!important;
};

._text-icon-huge {
    font-size: #{$typo-font-icon-huge}!important;
};
