﻿.menu {
    background-color: var(--background-color);
    position: absolute;
    box-shadow: 0px 3px 6px var(--shadow-color-opacity);
    margin-top: 2px;
    min-width: 180px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: $global-z-menu;

    &.-up {
        box-shadow: 0px -3px 6px var(--shadow-color-opacity);
    }

    .mi::before {
        margin-right: 0.6rem;
        font-size: $typo-font-icon;
    }

    li, button, .row-checkbox, .row-form, li.-link a {
        padding-left: 15px;
        padding-right: 15px;
    }

    li, li.-link a {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    li:hover:not(.active) {
        background-color: var(--background-hover);
        cursor: pointer;
    }

    li:active {
        background-color: var(--background-active);
    }
    
    li.-link {
        padding: 0;

        a {
            color: inherit;
            text-decoration: none;
            display: block;
        }
    }

    ul {
        list-style: none;
        padding-left: 0px;
    }
}
