@import "../_variables";

.badge {
    display: inline-block;
    padding: 0.4rem 0.7rem;
    font-size: 1.2rem;
    line-height: 1;
    color: $color-white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 1rem;

    &.-square {
        padding: 0;

        &:not(.-small) {
            height: 18px;
            width: 18px;
        }

        &.-small {
            padding: 0;
            height: 14px;
            width: 14px;
        }
    }

    &.-primary {
        background: var(--toggle-primary-primarydark);
    }

    &.-warning:not(.-secondary) {
        background: $color-warning;
    }

    &.-success:not(.-secondary) {
        background: $color-success;
    }

    &.-gray:not(.-secondary) {
        background: $color-gray-medium;
    }

    &.-blue:not(.-secondary) {
        background: $color-blue;
    }

    &.-yellow:not(.-secondary) {
        color: $color-black;
        background: $color-greenish-yellow;
    }

    &.-red:not(.-secondary) {
        background: $color-dark-red;
    }

    &.-secondary {
        color: var(--toggle-primary-white);
        border: 1px solid var(--toggle-primary-white);

        &.-warning {
            color: $color-warning;
            border: 1px solid $color-warning;
        }

        &.-success {
            color: $color-success;
            border: 1px solid $color-success;
        }

        &.-gray {
            color: $color-gray-medium;
            border: 1px solid $color-gray-medium;
        }

        &.-blue {
            color: $color-blue;
            border: 1px solid $color-blue;
        }

        &.-yellow {
            color: var(--toggle-black-pea);
            border: 1px solid $color-greenish-yellow;
        }

        &.-red {
            color: var(--toggle-dark-red-whitedark);
            border: 1px solid $color-dark-red;
        }
    }
}
