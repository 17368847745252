﻿@import 'variables';

.form-field-group {
    margin-top: 10px;
}

.form-field-group-content {
    margin-left: 15px;
    margin-right: 5px;
    display: flex;
    flex-wrap: wrap;

    .row-form {
        margin: 0;
        max-width: inherit;
    }
}

.column-span-1 {
    padding: 5px;
    min-width: 130px;
    max-width: 259px;
    box-sizing: border-box;
}

.column-span-2 {
    padding: 5px;
    min-width: 260px;
    max-width: 519px;
    box-sizing: border-box;
}

.column-span-4 {
    padding: 5px;
    min-width: 260px;
    max-width: 1039px;
    box-sizing: border-box;
}

// 0 bis 559 Pixel
.form-one-column {
    display: flex;
    flex-direction: column;
    min-width: 280px;

    .column-span-1 {
        width: 50%;
        max-width: 259px;
    }

    .column-span-2, .column-span-4 {
        width: 100%;
    }
}

// 450 bis 579 Pixel
.form-one-column-one-picture {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 150px;
    }

    .column-span-1 {
        width: 50%;
        max-width: 259px;
    }

    .column-span-2, .column-span-4 {
        width: 100%;
    }
}

// 580 bis 839 Pixel
.form-one-column-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 50%;
        max-width: 259px;
    }

    .column-span-2, .column-span-4 {
        width: 100%;
    }
}

// 840 bis 1099
.form-two-columns-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 25%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 50%;
    }

    .column-span-4 {
        width: 100%;
    }
}

// 1100 bis 1359
.form-three-columns-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 16.6%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 33%;
    }

    .column-span-4 {
        width: 100%;
    }
}

// 1360 bis 1619
.form-four-columns-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 12.5%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 25%;
    }

    .column-span-4 {
        width: 50%;
    }
}

// 1620 bis 1879
.form-five-columns-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 10%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 20%;
    }

    .column-span-4 {
        width: 50%;
    }
}

// 1880 bis 2139
.form-six-columns-two-pictures {
    display: flex;
    flex-direction: row;

    .fields-container {
        flex: 1;
    }

    .attachment-container {
        flex: 0 0 280px;
    }

    .column-span-1 {
        width: 8.3%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 16.6%;
    }

    .column-span-4 {
        width: 33%;
    }
}

// 560 bis 819
.form-two-columns {
    display: flex;
    flex-direction: column;

    .column-span-1 {
        width: 25%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 50%;
    }

    .column-span-4 {
        width: 100%;
    }
}

// 820 bis 1079
.form-three-columns {
    display: flex;
    flex-direction: column;

    .column-span-1 {
        width: 16.6%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 33%;
    }

    .column-span-4 {
        width: 100%;
    }
}

// 1080 bis 13399
.form-four-columns {
    display: flex;
    flex-direction: column;

    .column-span-1 {
        width: 12.5%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 25%;
    }

    .column-span-4 {
        width: 50%;
    }
}

// 1340 bis 1599
.form-five-columns {
    display: flex;
    flex-direction: column;

    .column-span-1 {
        width: 10%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 20%;
    }

    .column-span-4 {
        width: 50%;
    }
}

// 1600 bis 1859
.form-six-columns {
    display: flex;
    flex-direction: column;

    .column-span-1 {
        width: 8.3%;
        max-width: 259px;
    }

    .column-span-2 {
        width: 16.6%;
    }

    .column-span-4 {
        width: 33%;
    }
}

div[isoverlayingelement] {
    background-color: var(--field-background) !important;
    color: var(--text-color) !important;
    display: flex;
    align-items: center;
    position: relative;
    z-index: $global-z-overlay;
    box-sizing: border-box;
    border: none;
    border-bottom: $global-border-small solid var(--border-color);
    padding-left: $layout-space-smallest;
}


div[isoverlayingelement].overlay-element-readonly {
    color: var(--text-color-disabled) !important;
}