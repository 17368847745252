﻿/* 1. Color
   ========================================================================== */
$color-black: #191919; // rgb(25,25,25)
$color-white: #ffffff; // rgb(255,255,255); background color for light-mode
$color-whitedark: #ebebeb; // rgb(235,235,235); foreground color for dark mode

$color-primary: #0065bd; // rgb(0,101,189)
$color-primary-dark: #1395ef;   // rgb(97,180,243) Tonwert 500

$color-error: #c81c14;  // rgb(200, 28, 20)
$color-error-bg-light: #fdf8f8;
$color-warning: #f06118;  // rgb(240, 97, 24)
$color-success: #00AC6B;

$color-gray: #D7DAE2;
$color-gray-medium: #707070;
$color-light-gray: #f3f3f3;
$color-dark-gray: #393939;

$color-text-disabled-dark: #777777;
$color-bg-disabled-dark: #3C3C3C;
$color-bg-disabled: #CCE0F2;  // primary 20% border!

$tableconfig-bg-light: #CCE0F2;
$tableconfig-bg-dark: #142834;

$color-violet: #9E28B5;  //Bauleitung > Pendenz
$color-red: #C81C14; //Bauleitung > Mangel
$color-green: #00AC6B; //Bauleitung > Entscheid
$color-blue: #3C86C8; //Bauleitung > Auftrag

$color-light-red: #DF1F14;
$color-dark-red: #a41504;
$color-bluish-purple: #e9ddfd;

// analogous
$color-medium-blue: #0A0BC9;
$color-blueberry-blue: #0B41D4;
$color-cerulean: #0BABD4;
$color-dark-turquoise: #0AC9BD;

// complementary
$color-midnight-blue: #003C70;
$color-azure: #1994FF;
$color-antique-bronze: #704500;
$color-indochine: #BD7400;

// compound
$color-strong-blue: #1819F0;
$color-cloud-burst: #232357;
$color-brownish-orange: #C57831;
// $color-waning is last compound color

// double split complementary
$color-cobalt-blue: #090BBD;
$color-burnt-orange: #BD4C13;
$color-sahara: #BD9D13;
$color-topaz: #09BDB1;

// monochrom
$color-navy: #00213D;
// second color is the same as $color-blue
$color-dark-cyan-blue: #12293D;
$color-congress-blue: #00498A;

// shades
$color-dark-cerulean: #00437D;
// $color-navy same as monochrom
$color-science-blue: #006BC9;
$color-electric-blue: #0057A3;

// split complementary
$color-chestnut: #702D0B;
$color-brick-orange: #BD4609;
$color-greeny-brown: #705E0B;
$color-buddha-gold: #BD9C09;

// square
$color-blue-eyes: #136DBD;
$color-barney: #BD13A3;
$color-pirate-gold: #BD7B13;
$color-kelly-green: #35BD09;

// triad
$color-regal-blue: #0B4170;
$color-chilli-pepper: #BD1A13;
$color-pea: #B2BD13;
$color-brownish-green: #6A7006;

$color-greenish-yellow: #cfdb21;
$color-dark-gray: #424242;

/* 2. Global
   ========================================================================== */
// Border
$global-border-small: 0.0667rem; // 4k fix
$global-border-medium: 0.1rem;
$global-border-large: 0.2rem;


// LetterSpacing
$global-ls-medium: .1em;
$global-ls-normal: 0em;
$global-ls-small: .07em;

// Line Height
$global-lh-normal: normal;
$global-lh-none: 100%;
$global-lh-small: 120%;
$global-lh-medium: 150%;
$global-lh-large: 200%;
$global-lh-huge: 250%;

// z-index
$global-z-hover-layer: 1;
$global-z-overlay: 100;
$global-z-bottom-toolbar: 200;
$global-z-navigation: 300;
$global-z-header: 400;
$global-z-menu: 500;

$global-z-modal: 1000;
$global-z-modal-overlay: 1100;

$global-z-error: 2000;

/* 3. Layout
   ========================================================================== */
// Radius
$layout-radius-circle : 50%;
$layout-radius-large: 20px;
$layout-radius-medium: 10px;
$layout-radius-small: 4px;
$layout-radius-smaller: 3px;
$layout-radius-smallest: 2px;
$layout-radius-none: 0;

// Spaces
$layout-space-giant: 8.0rem;
$layout-space-huge: 6.4rem;
$layout-space-larger: 3.2rem;
$layout-space-large: 2.4rem;
$layout-space-medium: 1.2rem;
$layout-space-small: 0.8rem;
$layout-space-smaller: 0.5rem;
$layout-space-smallest: 0.3rem;

$layout-full-size: 100%;

$layout-min-width: 35rem;
$layout-min-height: 30rem;

/* 4. Typography
   ========================================================================== */
// Fonts
$typo-font-primary: 'roboto', sans-serif;
$typo-font-title: 'roboto', sans-serif;
$typo-font-icons: 'messerli-icon-font';

// Roboto
$roboto-font-path: 'fonts/roboto';
$roboto-font-name: 'roboto';
$roboto-font-version: '2.137';

// Base
$typo-font-base-size: 10px;
$typo-font-fallback: sans-serif;

// Weight
$typo-font-weight-bold: 700;
$typo-font-weight-medium: 500;
$typo-font-weight-regular: 400;
$typo-font-weight-light: 300;

// Size 2.5rem == 25px
$typo-font-giant: 6.4rem;
$typo-font-huge: 3.5rem;
$typo-font-largest: 2.8rem;
$typo-font-larger: 2.0rem;
$typo-font-large: 1.6rem;
$typo-font-medium: 1.4rem;
$typo-font-std: 1.4rem;
$typo-font-small: 1.2rem;
$typo-font-smaller: 1.0rem;
$typo-font-smallest: 0.8rem;
$typo-font-icon: 1.6rem;
$typo-font-icon-small: 1.4rem;
$typo-font-icon-large: 1.8rem;
$typo-font-icon-huge: 3.5rem;


/* 5. Media screen
   ========================================================================== */

// Attention: Keep values synchronous with those in BreakPoint.cs and breakPoint.js

// old breakpoint variables are kept vor compatibility
$small-max-width: 767px;
$medium-min-width: 768px;
$medium-max-width: 1439px;
$large-min-width: 1440px;

$breakpoints-min: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
) !default;

$breakpoints-max: (
        xs: 575px,
        sm: 767px,
        md: 991px,
        lg: 1199px,
        xl: 1439px
) !default;

/* Function
========================================================================== */

@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

@mixin light-mode {
    --background-color: var(--color-white);
    --background-color-opacity: rgba(var(--color-black-rgb),0.6);
    --text-color: var(--color-black);
    --toggle-red-whitedark: #{$color-error};
    --toggle-dark-red-whitedark: #{$color-dark-red};
    --toggle-orange-whitedark: #{$color-warning};
    --toggle-white-black: #{$color-white};
    --toggle-black-pea: var(--color-black);
    --field-background: #F7F7F7;
    --field-background-hover: #F3F3F3;
    --tile-background: #F3F3F3;
    --tile-background-hover: #F3F3F3;
    --field-background-focus: #EDEDED;
    --field-background-stronger: #E7E7E7;
    --field-background-error: #{$color-error-bg-light};
    --primary-text-color: var(--primary-color);
    --primary-color-disabled: #{$color-bg-disabled};
    --primary-text-color-disabled: var(--color-white);
    --secondary-text-color-disabled: #{$color-bg-disabled};
    --border-color: var(--color-black);
    --border: #{$global-border-small} solid var(--color-black);
    --border-color-disabled: #E7E7E7;
    --text-color-disabled: #999999;
    --shadow-color-opacity: rgba(var(--color-black-rgb),0.16);
    --toggle-primary-white: #{$color-primary};
    --toggle-primary-primarydark: #{$color-primary};
    --background-active: #D9E8F5;
    --background-active-higher-opacity: #B2D0EB;
    --link-active: #CCE0F2;
    --background-hover: #F3F3F3;
    --link-hover: #F3F3F3;
    --table-border: #{$global-border-small} solid #E7E7E7;
    --tableconfig-bg: #{$tableconfig-bg-light};
    --button-selected-background: #E5EFF8;
    --button-selected-color: var(--primary-color);
    --button-selected-border: var(--primary-color);
    --tab-border-color: #EDEDED;
    --function-bar-background: #{$color-light-gray};
    --placeholder-icon-color: #E7E7E7;
    --link-decoration: #{none};
    --outline-color: #{$color-gray};
    --outline-hover-color: var(--color-black);
    --floating-button-background: rgba(255, 255, 255,0.9);
    --floating-button-main-btn-background: #{$color-primary};
    --tertiary-hover-color: #{$color-light-gray};
    --background-error-hover: #F9F0F0;
    --background-warning-hover: #FCF4F0;
    --toast-info-background: #66A2D7;
    --toast-success-background: #66CDA6;
    --toast-warning-background: #F6A074;
    --toast-error-background: #DE7672;
    --icon-placeholder-background: #F2F7FD;
    --icon-drag-indicator: url("images/icons/drag-indicator.svg");
    --icon-drag-horizontally: url("images/icons/drag-horizontally.svg");
    --icon-dropdown-arrow: url("images/icons/dropdown-arrow.svg");
    --icon-dropdown-arrow-focus: url("images/icons/dropdown-arrow-focus.svg");
    --icon-cb-enabled-unselected: url("images/icons/cb-enabled-unselected.svg");
    --icon-cb-enabled-selected: url("images/icons/cb-enabled-selected.svg");
    --icon-cb-selected-focus: url("images/icons/cb-enabled-selected-focus.svg");
    --icon-cb-unselected-focus: url("images/icons/cb-enabled-unselected-focus.svg");
    --icon-cd-indeterminate: url("images/icons/cb-enabled-indeterminate.svg");
    --icon-cd-indeterminate-focus: url("images/icons/cb-enabled-indeterminate-focus.svg");
    --icon-cd-disabled-indeterminate: url("images/icons/cb-disabled-indeterminate.svg");
    --icon-radio-btn-unselected: url("images/icons/radio-btn-unselected.svg");
    --icon-radio-btn-selected: url("images/icons/radio-btn-selected.svg");
    --icon-radio-btn-unselected-focus: url("images/icons/radio-btn-unselected-focus.svg");
    --icon-radio-btn-selected-focus: url("images/icons/radio-btn-selected-focus.svg");
    --image-decoration-small: url(images/decoration-default-small.png);

    input::-webkit-calendar-picker-indicator {
        filter: invert(0);
    }
}

@mixin dark-mode {
    --background-color: var(--color-black);
    --background-color-opacity: rgba(255,255,255,0.15);
    --text-color: var(--color-white-dark);
    --toggle-dark-red-whitedark: #{$color-whitedark};
    --toggle-red-whitedark: #{$color-whitedark};
    --toggle-orange-whitedark: #{$color-whitedark};
    --toggle-white-black: #{$color-black};
    --toggle-black-pea: #{$color-greenish-yellow};
    --field-background: #2E2E2E;
    --field-background-hover: #3A3A3A;
    --field-background-focus: #{$color-dark-gray};
    --field-background-stronger: #333333;
    --field-background-error: #2E2E2E;
    --tile-background: #3A3A3A;
    --tile-background-hover: #3A3A3A;
    --primary-text-color: var(--color-white-dark);
    --primary-color-disabled: #{$color-bg-disabled-dark};
    --primary-text-color-disabled: #{$color-text-disabled-dark};
    --secondary-text-color-disabled: #{$color-text-disabled-dark};
    --border-color: var(--color-white-dark);
    --border: #{$global-border-small} solid var(--color-white-dark);
    --border-color-disabled: #2E2E2E;
    --text-color-disabled: #6C6C6C;
    --shadow-color-opacity: rgba(var(--color-whitedark-rgb),0.16);
    --toggle-primary-white: #{$color-whitedark};
    --toggle-primary-primarydark: #{$color-primary-dark};
    --background-active: #44769C;
    --background-active-higher-opacity: #5295C7;
    --link-active: #14283A;
    --background-hover: #2E2E2E;
    --link-hover: #242424;
    --tableconfig-bg: #{$tableconfig-bg-dark};
    --table-border: #{$global-border-small} solid #2E2E2E;
    --button-selected-background: #3A3A3A;
    --button-selected-color: var(--color-white);
    --button-selected-border: var(--color-gray);
    --tab-border-color: #5295C7;
    --function-bar-background: #{$color-dark-gray};
    --placeholder-icon-color: #2E2E2E;
    --link-decoration: #{underline};
    --outline-color: #{$color-gray};
    --outline-hover-color: var(--color-white-dark);
    --floating-button-background: rgba(0,0,0,0.8);
    --floating-button-main-btn-background: #{$color-primary-dark};
    --background-error-hover: #2B1818;
    --background-warning-hover: #2F2018;
    --tertiary-hover-color: #{$color-dark-gray};
    --toast-info-background: #476B8B;
    --toast-success-background: #47856D;
    --toast-warning-background: #9D6A4F;
    --toast-error-background: #8F504E;
    --icon-placeholder-background: #242424;
    --icon-drag-indicator: url("images/icons/drag-indicator-dark.svg");
    --icon-drag-horizontally: url("images/icons/drag-horizontally-dark.svg");
    --icon-dropdown-arrow: url("images/icons/dropdown-arrow-dark.svg");
    --icon-dropdown-arrow-focus: url("images/icons/dropdown-arrow-dark-focus.svg");
    --icon-cb-enabled-unselected: url("images/icons/cb-enabled-unselected-dark.svg");
    --icon-cb-enabled-selected: url("images/icons/cb-enabled-selected-dark.svg");
    --icon-cb-selected-focus: url("images/icons/cb-enabled-selected-dark-focus.svg");
    --icon-cb-unselected-focus: url("images/icons/cb-enabled-unselected-dark-focus.svg");
    --icon-cd-indeterminate: url("images/icons/cb-enabled-indeterminate-dark.svg");
    --icon-cd-indeterminate-focus: url("images/icons/cb-enabled-indeterminate-focus-dark.svg");
    --icon-cd-disabled-indeterminate: url("images/icons/cb-disabled-indeterminate-dark.svg");
    --icon-radio-btn-unselected: url("images/icons/radio-btn-unselected-dark.svg");
    --icon-radio-btn-selected: url("images/icons/radio-btn-selected-dark.svg");
    --icon-radio-btn-unselected-focus: url("images/icons/radio-btn-unselected-dark-focus.svg");
    --icon-radio-btn-selected-focus: url("images/icons/radio-btn-selected-dark-focus.svg");
    --image-decoration-small: url("images/decoration-dark-small.png");

    input::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}
