﻿@import 'variables';

.small-only {
    @media (min-width: map-get($breakpoints-min, 'md')) {
        display: none;
    }
}

.medium-only {
    @media (max-width: map-get($breakpoints-max, 'sm')), (min-width: map-get($breakpoints-min, 'xxl')) {
        display: none;
    }
}

.large-only {
    @media (max-width: map-get($breakpoints-max, 'xl')) {
        display: none;
    }
}

.medium-up {
    @media (max-width: map-get($breakpoints-max, 'sm')) {
        display: none;
    }
}

.medium-down {
    @media (min-width: map-get($breakpoints-min, 'xxl')) {
        display: none;
    }
}
