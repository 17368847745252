@import "mixins/breakpoint";

@each $breakpoint in map-keys($breakpoints-min) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-min);
    ._justify-content#{$infix}-around { justify-content: space-around; }
    ._justify-content#{$infix}-between { justify-content: space-between; }
    ._justify-content#{$infix}-center { justify-content: center; }
    ._justify-content#{$infix}-end { justify-content: end; }
    ._justify-content#{$infix}-start { justify-content: start; }
    ._justify-self#{$infix}-center { justify-self: center; }
    ._justify-self#{$infix}-end { justify-self: end; }
    ._justify-self#{$infix}-start { justify-self: start; }
    ._align-items#{$infix}-center { align-items: center;}
    ._align-items#{$infix}-start { align-items: start;}
    ._align-items#{$infix}-end { align-items: end;}
    ._align-self#{$infix}-center { align-self: center;}
    ._align-self#{$infix}-start { align-self: start;}
    ._align-self#{$infix}-end { align-self: end;}
  }
}
