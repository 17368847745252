.flex-column {
  -ms-flex-direction:column!important;
  flex-direction:column!important
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-grow-1 {
    flex-grow: 1;
}
