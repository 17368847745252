﻿@import 'variables';

:root {
    // General
    --color-black: #{$color-black};
    --color-black-rgb: 25,25,25;
    --color-white: #{$color-white};
    --color-white-dark: #{$color-whitedark};
    --color-white-rgb: 255,255,255; // background color for light-mode
    --color-whitedark-rgb: 235,235,235; // foreground color for dark mode
    --color-gray: #{$color-gray};
    --gray-border-color: #{$color-gray};
    --gray-border: #{$global-border-small} solid #{$color-gray};
    --primary-color: #{$color-primary};
    --primary-color-rgb: 0,101,189;
    --primary-dark-rgb: 97,180,243;
    --color-error-rgb: 200, 28, 20;
    --color-warning-rgb: 240, 97, 24;
    --color-label-placeholder: rgba(var(--color-black-rgb),0.6);

    --color-medium-blue: #{$color-medium-blue};
    --color-blueberry-blue: #{$color-blueberry-blue};
    --color-cerulean: #{$color-cerulean};
    --color-dark-turquoise: #{$color-dark-turquoise};

    --color-midnight-blue: #{$color-midnight-blue};
    --color-azure: #{$color-azure};
    --color-antique-bronze: #{$color-antique-bronze};
    --color-indochine: #{$color-indochine};

    --color-strong-blue: #{$color-strong-blue};
    --color-cloud-burst: #{$color-cloud-burst};
    --color-brownish-orange: #{$color-brownish-orange};

    --color-cobalt-blue: #{$color-cobalt-blue};
    --color-burnt-orange: #{$color-burnt-orange};
    --color-sahara: #{$color-sahara};
    --color-topaz: #{$color-topaz};

    --color-navy: #{$color-navy};
    --color-dark-cyan-blue: #{$color-dark-cyan-blue};
    --color-congress-blue: #{$color-congress-blue};

    --color-dark-cerulean: #{$color-dark-cerulean};
    --color-science-blue: #{$color-science-blue};
    --color-electric-blue: #{$color-electric-blue};

    --color-chestnut: #{$color-chestnut};
    --color-brick-orange: #{$color-brick-orange};
    --color-greeny-brown: #{$color-greeny-brown};
    --color-buddha-gold: #{$color-buddha-gold};

    --color-blue-eyes: #{$color-blue-eyes};
    --color-barney: #{$color-barney};
    --color-pirate-gold: #{$color-pirate-gold};
    --color-kelly-green: #{$color-kelly-green};

    --color-regal-blue: #{$color-regal-blue};
    --color-chilli-pepper: #{$color-chilli-pepper};
    --color-pea: #{$color-pea};
    --color-brownish-green: #{$color-brownish-green};

    --color-light-red: #{$color-light-red};
    --color-dark-red: #{$color-dark-red};
    --color-bluish-purple: #{$color-bluish-purple};
    --color-warning: #{$color-warning};

    // Light-mode
    @include light-mode;
    // user set dark-mode while system has light-mode
    &.dark-color-scheme {
        @include dark-mode;
    }
    // Dark-mode
    @media (prefers-color-scheme: dark) {
        @include dark-mode;
        // user set light-mode while system has dark-mode
        &.light-color-scheme {
            @include light-mode;
        }
    }
    
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: #{$typo-font-primary};
    font-size: #{$typo-font-base-size};
    caret-color: var(--toggle-primary-primarydark);
}

body {
    font-size: #{$typo-font-std};
    font-weight: #{$typo-font-weight-regular};
    line-height: #{$global-lh-medium};
    margin: 0;
}

.hidden {
    display:none !important;  // Überschreibt Flexbox
}

img {
    display: block;
}

:disabled {
    cursor: not-allowed;
}

:first-child {
    margin-top: 0;
    margin-left: 0;
}

:last-child {
    margin-bottom: 0;
    margin-right: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.float-left {
    float:left;
}

.float-right {
    float:right;
}

.clear {
    clear: both;
}

.clear-fix::after {
    content: "";
    clear: both;
    display: table;
}

.text-center {
    text-align:center;
}

.scrollable {
    overflow: auto;
}

.error {
    color: $color-error;
}

.svg-loader {
    display: flex;
    position: relative;
    align-content: space-around;
    justify-content: center;
    img{
        width: 50px;
        height: 50px;
        padding-top: 50px;
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.cut-text-add-dots {
    text-overflow: ellipsis;
    overflow: hidden;    
    white-space: nowrap;
}

.image-decoration-small {
    background-image: var(--image-decoration-small);
    background-color: var(--background-color);
}