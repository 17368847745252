@import 'mixins/breakpoint';

// Margin and Padding

$spacers: (
        0: 0,
        1: $layout-space-smallest,
        2: $layout-space-smaller,
        3: $layout-space-small,
        4: $layout-space-medium,
        5: $layout-space-large,
        6: $layout-space-larger,
        7: $layout-space-huge,
        8: $layout-space-giant,
) !default;

@each $breakpoint in map-keys($breakpoints-min) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-min);

    @each $prop, $abbrev in (margin: _m, padding: _p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        ._m#{$infix}-n#{$size} { margin: -$length !important; }
        ._mt#{$infix}-n#{$size},
        ._my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        ._mr#{$infix}-n#{$size},
        ._mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        ._mb#{$infix}-n#{$size},
        ._my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        ._ml#{$infix}-n#{$size},
        ._mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    ._m#{$infix}-auto { margin: auto !important; }
    ._mt#{$infix}-auto,
    ._my#{$infix}-auto {
      margin-top: auto !important;
    }
    ._mr#{$infix}-auto,
    ._mx#{$infix}-auto {
      margin-right: auto !important;
    }
    ._mb#{$infix}-auto,
    ._my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    ._ml#{$infix}-auto,
    ._mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}
