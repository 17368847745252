@import "variables";
@import "mixins/breakpoint";

@each $breakpoint in map-keys($breakpoints-min) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints-min);

    @each $prop, $abbrev in (display: _d) {
      .#{$abbrev}#{$infix}-block { display: block; }
      .#{$abbrev}#{$infix}-none { display: none; }
      .#{$abbrev}#{$infix}-inline { display: inline; }
      .#{$abbrev}#{$infix}-inline-flex { display: inline-flex; }
      .#{$abbrev}#{$infix}-inline-block { display: inline-block; }
      .#{$abbrev}#{$infix}-flex { display: flex; }
      .#{$abbrev}#{$infix}-grid { display: grid; }
    }
  }
}

._invisible {
  opacity: 0;
}
