﻿table {
    border-collapse: seperate;
    border-spacing: 0;
    border-radius: 0;
    empty-cells: show;
    font-size: $typo-font-std;
    table-layout: fixed;


    th, td {
        font-size: inherit;
        margin: 0;
        overflow: hidden;
        white-space: normal;
        padding: .5em .5em;
        text-align: left;
        border-bottom: var(--table-border);
    }    

    th {
        font-weight: $typo-font-weight-medium;
    }
  
    tr:hover {
        cursor: pointer;

        &:not(.selected) td {
            background-color: var(--background-hover);
        }
    }

    .selected td {
        background-color: var(--background-active);
        /*color: var(--color-white);*/
    }
    // sort
    th span {
        padding-left: $layout-space-smallest;
    }
    /*thead {
        background-color: var(--background-color);
        vertical-align: bottom;
    }*/
}
