﻿/*.valid.modified:not([type=checkbox]) {
    outline: 1px solid #{$color-success};
}*/

label:has(+ select.invalid), label.invalid {
    color: $color-error;
}

.invalid:not(label) {
    border-bottom-color: #{$color-error} !important;
    background: var(--field-background-error);
    color: $color-error;
}

.validation-message {
    color: $color-error;
    font-size: $typo-font-small;
}

#blazor-error-ui {
    background: lightyellow;
    bottom: 0;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    padding: 0.6rem 1.25rem 0.7rem 1.25rem;
    position: fixed;
    width: 100%;
    z-index: $global-z-error;
}

#blazor-error-ui .dismiss {
    cursor: pointer;
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
}
