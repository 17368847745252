﻿/* 
 -input, textarea, select
    <div class="row-form">
        <label for="name">Displaytext</label>
        <input type="text" id="name" name="name">
    </div>
    <div class="row-form">
        <label for="name">Displaytext</label>
        <textarea id="name" name="name" rows="4"></textarea>
    </div> 
    <div class="row-form">
        <label for="name">Displaytext</label>
        <select id="name" name="name" value="">
        ...
        </select>
    </div>
*/

/*  row */
.row-form {
    position: relative;
    align-items: center;
    margin: $layout-space-medium 0 $layout-space-medium 0;
    max-width: 300px;

    label {
        display: block;
        font-size: $typo-font-small;
        padding-left: $layout-space-smallest;
    }
}

.row-directory {
    label {        
        font-size: $typo-font-std;
        font-weight: $typo-font-weight-regular;
    }

    .mi::before {
        margin-right:5px;
    }
}

/* fildeset */
fieldset {
    margin: $layout-space-medium 0;
    border: var(--border);
    border-radius: $layout-radius-small;
}

legend {
    padding: 0 $layout-space-small;
}
