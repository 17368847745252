﻿/* Overriding rules Daterangepicker-Framework wwwroot\_content\BlazorDateRangePicker\daterangepicker.min.css*/
@import 'variables';

div.daterangepicker {
    background-color: var(--background-color);
    box-shadow: 0px 3px 6px var(--shadow-color-opacity);
    border-radius: 0;
    border: none;
    margin-top: 2px;
    overflow: auto;

    @media (max-width: map-get($breakpoints-max, 'sm')) {
        bottom: 5px;
    }
}

div.daterangepicker::before, div.daterangepicker::after {
    display: none;
}

div.daterangepicker .calendar-table {
    background-color: var(--background-color);
    border:none;
}

// Calendar-table
div.daterangepicker .calendar-table td {
    border-radius: 0px;
    font-size: #{$typo-font-std};
    font-family: #{$typo-font-primary};
    border: none;
}

div.daterangepicker td.available:hover {
    background-color: inherit;
    border: none;
    color: inherit;
}

div.daterangepicker td {

    &.weekend {
        background-color: var(--field-background-stronger);
    }

    &.in-range, &.in-range.off, &.start-date, &.end-date {
        color: var(--toggle-white-black) !important;
        background-color: var(--toggle-primary-primarydark) !important;

        &:hover {
            filter: brightness(1.2);
            /*background-color: lighten($color-primary, 5%) !important;
            color: var(--color-white) !important;*/
        }
    }

    &.off {
        color: var(--text-color-disabled);
        background-color: var(--background-color);
    }
}

// Calendar-table select
div.daterangepicker select.monthselect, div.daterangepicker select.yearselect {
    font-size: $typo-font-std;
    font-family: roboto, sans-serif;
    padding: $layout-space-small $layout-space-smallest;
}

div.daterangepicker select.monthselect {
    width: 65%;
}

div.daterangepicker .calendar-table .next span, div.daterangepicker .calendar-table .prev span {
    border-color: var(--text-color);

    &:hover {
        border-color: var(--toggle-primary-primarydark);
        background-color: inherit;
    }
}

// Calendar-table weekdays
div.daterangepicker .calendar-table th {
    font-size: #{$typo-font-std};
    font-family: roboto, sans-serif;
}

// Left DateRangeFunctions
div.daterangepicker .ranges li {
    font-size: #{$typo-font-medium};
    font-family: roboto, sans-serif;
    width: 110px;
    float: left;


    &.active {
        color: var(--color-white);
        background-color: var(--toggle-primary-primarydark);

        &:hover {
            background-color: lighten($color-primary, 5%);
            color: var(--color-white);
        }
    }

    &:hover {
        color: var(--toggle-primary-primarydark);
        background-color: rgba(var(--primary-color-rgb),0.05);
        cursor: pointer;
        box-shadow: 0px -1px 0px $color-primary inset;
    }

    &:nth-child(9) {
        display: none;
    }
}

// Buttonrow
div.daterangepicker .drp-buttons {
    padding: 15px;
    border-top: var(--border);
}

div.drp-buttons button {
    font-size: #{$typo-font-medium};
    font-family: roboto, sans-serif;
}