﻿input, textarea, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-size: $typo-font-std;
    background-color: var(--field-background);
    color: var(--text-color);
    font-size: $typo-font-std;
    border: none;
    border-bottom: $global-border-small solid var(--border-color);
    border-top-left-radius: $layout-radius-small;
    border-top-right-radius: $layout-radius-small;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: $layout-space-small $layout-space-smallest;
    width: 100%;
    box-sizing: border-box;

    &::-ms-expand {
        display: none;
    }

    &:focus {
        outline: none;
        border-color: var(--toggle-primary-primarydark);
        background-color: var(--field-background-focus);
    }

    &:hover:not(:disabled, .readonly) {
        border-color: var(--toggle-primary-primarydark);
        background-color: var(--field-background-hover);
    }

    &:disabled:not(.readonly) {
        background-color: var(--field-background);
        color: var(--text-color-disabled);
        border-color: var(--border-color-disabled);
        cursor: default;
    }

    &.readonly {
        background: none;
        border-color: var(--border-color-disabled);
        color: var(--text-color);
        cursor: default;
    }
}

input, select {
    height: 3.4rem;
}

input[type=date], input[type=time] {   

    &:hover:not(:disabled) {
        cursor: pointer;
    }
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    height: 1.0em;
}

input::-webkit-datetime-edit-fields-wrapper, input::-webkit-datetime-edit-day-field, input::-webkit-datetime-edit-month-field, input::-webkit-datetime-edit-year-field {
    padding: 0;
    border: none;
}

input::-webkit-calendar-picker-indicator {
    margin-inline-start: 3px;
}
/* outlined style */
input.-outlined, .input.-outlined {
    border: $global-border-small solid var(--outline-color);
    border-radius: $layout-radius-small;
    background: var(--background-color);
    padding-top: 1rem;
    padding-bottom: 0.7rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover:not(:disabled):not(:active):not(:focus) {
        border: $global-border-small solid var(--outline-hover-color);
        background: var(--background-color);
    }

    &:focus, &:active {
        outline: none;
        border: $global-border-small solid var(--toggle-primary-primarydark);
        background: var(--background-color);
    }

    &:disabled {
        border: $global-border-small solid var(--border-color-disabled);
    }

    &.readonly {
        border: $global-border-small solid var(--border-color-disabled);
    }
}
/* textarea */
textarea {
    resize: vertical;
    overflow: auto;
}