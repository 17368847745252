﻿$hasklig-font-path: 'fonts/hasklig';
$hasklig-font-name: 'hasklig';
$hasklig-font-version: '1.1';

@mixin hasklig-font-definition-woff2($font-type) {
    src: url('#{$hasklig-font-path}/#{$hasklig-font-name}-#{$font-type}.woff2?v=#{$hasklig-font-version}') format('woff2');
}

@font-face {
    font-family: Hasklig;
    font-weight: normal;
    font-style: normal;
    @include hasklig-font-definition-woff2('regular');
}
