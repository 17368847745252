@import '../_variables';

@keyframes radial-progress {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(365deg) }
}

.app-loading {
  .app-icon-container {
    position: relative;

    .app-icon {
      background-image: var(--app-image);
      background-size: cover;
      height: 20rem;
      width: 20rem;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
    }

    @media (prefers-color-scheme: dark) {
      .app-icon {
        background-image: var(--app-image-dark);
      }
    }

    .progress-radial {
      width: 21rem;
      height: 21rem;
      border-radius: 50%;
      background: radial-gradient(closest-side, var(--icon-placeholder-background) 94%, transparent 95%), conic-gradient(var(--icon-placeholder-background) 25%, var(--primary-color) 40%, var(--primary-color) 70%, var(--icon-placeholder-background) 0);
      transform: rotate(15deg);
      animation: infinite linear 4s;
      animation-name: radial-progress;
    }
  }

  h3 {
    text-align: center;
  }

  .loading-progress-text:after {
    content: var(--blazor-load-percentage-text, "0%");
  }
}
