@import '../_variables';

.alert {
  display: flex;
  flex-direction: row;
  border-radius: $layout-radius-small;

  //basic style should only be used as message and NOT as toast notification
  &.-basic {
    background-color: var(--field-background);
    border: var(--gray-border)
  }

  &.-info {
    background-color: var(--toast-info-background);
  }

  &.-success {
    background-color: var(--toast-success-background);
  }

  &.-warning {
    background-color: var(--toast-warning-background);
  }

  &.-error {
    background-color: var(--toast-error-background);
  }
  
  .close-icon {
    i:before {
      font-size: $typo-font-small;
      font-weight: $typo-font-weight-bold;
    }
  }
  
  .icon {
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-size: 2.5rem;
    padding: 1.5rem 0 1.5rem 1.5rem;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.5rem 1.5rem;

    .title {
      font-size: $typo-font-large;
      font-weight: $typo-font-weight-medium;
      margin-bottom: 0.9rem;
    }

    .title:empty,
    .title:has( + :empty) {
      margin-bottom: 0;
    }
  }
}
