$messerli-icons-codepoints: (
    mi-abschliessen:"\E001",
    mi-add-canvas:"\E002",
    mi-add-document:"\E003",
    mi-add-new-item:"\E004",
    mi-add-photo:"\E005",
    mi-add-recording:"\E006",
    mi-add-signature:"\E007",
    mi-add-smart-entry:"\E008",
    mi-add-sollzeit:"\E009",
    mi-adr-email:"\E00A",
    mi-adr-mobile:"\E00B",
    mi-adr-person:"\E00C",
    mi-adr-route:"\E00D",
    mi-adr-tel:"\E00E",
    mi-adr-www:"\E00F",
    mi-angestellter:"\E010",
    mi-attachment:"\E011",
    mi-attendance:"\E012",
    mi-audio-pause:"\E013",
    mi-audio-play:"\E014",
    mi-audio-record-start:"\E015",
    mi-audio-stop:"\E016",
    mi-auftrag-lager:"\E017",
    mi-auftrag-ohne:"\E018",
    mi-auftrag-regie:"\E019",
    mi-auftrag-sammel:"\E01A",
    mi-auftrag-sub-lager:"\E01B",
    mi-auftrag-sub-regie:"\E01C",
    mi-auftrag-sub-teilleistung-regie:"\E01D",
    mi-auftrag-sub-teilleistung:"\E01E",
    mi-auftrag-sub-unproduktiv:"\E01F",
    mi-auftrag-sub:"\E020",
    mi-auftrag-teilleistung-regie:"\E021",
    mi-auftrag-teilleistung:"\E022",
    mi-auftrag-unproduktiv:"\E023",
    mi-auftrag:"\E024",
    mi-auftragsgruppe:"\E025",
    mi-avatar:"\E026",
    mi-avis-mark-send:"\E027",
    mi-avis:"\E028",
    mi-back-edit:"\E029",
    mi-bereich:"\E02A",
    mi-buchung-regie:"\E02B",
    mi-buchung-teilleistung:"\E02C",
    mi-buchung-unproduktiv:"\E02D",
    mi-calendar:"\E02E",
    mi-cancel:"\E02F",
    mi-cb-enabled-indeterminate:"\E030",
    mi-cb-enabled-selected:"\E031",
    mi-cb-enabled-unselected:"\E032",
    mi-check-all:"\E033",
    mi-checkmark-circled:"\E034",
    mi-circel-left:"\E035",
    mi-circel-right:"\E036",
    mi-close-circled:"\E037",
    mi-close:"\E038",
    mi-cloud-download:"\E039",
    mi-cloud-upload:"\E03A",
    mi-columns-managment:"\E03B",
    mi-columns-max-resize:"\E03C",
    mi-columns-settings:"\E03D",
    mi-copy-fill:"\E03E",
    mi-copy:"\E03F",
    mi-cost-absenzen:"\E040",
    mi-cost-arbeitsstunden-nicht-belastbar:"\E041",
    mi-cost-arbeitsstunden-regie:"\E042",
    mi-cost-arbeitsstunden-teilleistung-regie:"\E043",
    mi-cost-arbeitsstunden-teilleistung:"\E044",
    mi-cost-arbeitsstunden:"\E045",
    mi-cost-auszahlung:"\E046",
    mi-cost-compesnsation:"\E047",
    mi-cost-document:"\E048",
    mi-cost-eat:"\E049",
    mi-cost-ferien:"\E04A",
    mi-cost-krankheit:"\E04B",
    mi-cost-pause:"\E04C",
    mi-cost-sonderkosten:"\E04D",
    mi-cost-spesen-nicht-belastbar:"\E04E",
    mi-cost-spesen-regie:"\E04F",
    mi-cost-spesen-teilleistung-regie:"\E050",
    mi-cost-spesen-teilleistung:"\E051",
    mi-cost-spesen:"\E052",
    mi-cost-umbuchungen:"\E053",
    mi-cost-unfall:"\E054",
    mi-cut:"\E055",
    mi-day:"\E056",
    mi-delete:"\E057",
    mi-directory-close:"\E058",
    mi-directory-open:"\E059",
    mi-drag-horizontally:"\E05A",
    mi-drag-indicator:"\E05B",
    mi-dropdown-arrow:"\E05C",
    mi-edit:"\E05D",
    mi-email-alert:"\E05E",
    mi-email:"\E05F",
    mi-erase:"\E060",
    mi-erledigt:"\E061",
    mi-expenses:"\E062",
    mi-external-drive:"\E063",
    mi-file-audio:"\E064",
    mi-file-excel:"\E065",
    mi-file-image:"\E066",
    mi-file-movie:"\E067",
    mi-file-pdf:"\E068",
    mi-file-word:"\E069",
    mi-file:"\E06A",
    mi-filter:"\E06B",
    mi-fix-sidebar-2:"\E06C",
    mi-fix-sidebar:"\E06D",
    mi-gruppierung:"\E06E",
    mi-hard-disk:"\E06F",
    mi-holding-firma-filiale:"\E070",
    mi-holding-firma:"\E071",
    mi-holding:"\E072",
    mi-image:"\E073",
    mi-info-outlined:"\E074",
    mi-info:"\E075",
    mi-keine-eintraege:"\E076",
    mi-kostenstelle:"\E077",
    mi-list-placeholder:"\E078",
    mi-list-read:"\E079",
    mi-logout:"\E07A",
    mi-mail-reply:"\E07B",
    mi-masseneingabe:"\E07C",
    mi-month:"\E07D",
    mi-move-item:"\E07E",
    mi-move:"\E07F",
    mi-multiple-selection-undo:"\E080",
    mi-multiple-selection:"\E081",
    mi-nav-adressverwaltung:"\E082",
    mi-nav-auftragsbearbeitung:"\E083",
    mi-nav-bauadministration:"\E084",
    mi-nav-bauleitung-abnahme-objekt:"\E085",
    mi-nav-bauleitung-abnahme-werk:"\E086",
    mi-nav-bauleitung-abnahme:"\E087",
    mi-nav-bauleitung-auftraege:"\E088",
    mi-nav-bauleitung-baujournal:"\E089",
    mi-nav-bauleitung-besprechungen:"\E08A",
    mi-nav-bauleitung-entscheide:"\E08B",
    mi-nav-bauleitung-gesamtliste:"\E08C",
    mi-nav-bauleitung-maengel:"\E08D",
    mi-nav-bauleitung-notizen:"\E08E",
    mi-nav-bauleitung-pendenzen:"\E08F",
    mi-nav-bauleitung-projektadressen:"\E090",
    mi-nav-bauleitung-vorerfassen:"\E091",
    mi-nav-bauleitung:"\E092",
    mi-nav-crm:"\E093",
    mi-nav-demo-account:"\E094",
    mi-nav-demo-button:"\E095",
    mi-nav-demo-color:"\E096",
    mi-nav-demo-components:"\E097",
    mi-nav-demo-dynamic-form:"\E098",
    mi-nav-demo-elements:"\E099",
    mi-nav-demo-file-upload:"\E09A",
    mi-nav-demo-form-row:"\E09B",
    mi-nav-demo-form:"\E09C",
    mi-nav-demo-grid-view-config:"\E09D",
    mi-nav-demo-grid-view:"\E09E",
    mi-nav-demo-icon:"\E09F",
    mi-nav-demo-master-detail-view:"\E0A0",
    mi-nav-demo-modal:"\E0A1",
    mi-nav-demo-tile-view:"\E0A2",
    mi-nav-demo-toolbar:"\E0A3",
    mi-nav-demo-typography:"\E0A4",
    mi-nav-dokument:"\E0A5",
    mi-nav-einstellungen-allgemein:"\E0A6",
    mi-nav-einstellungen-farben:"\E0A7",
    mi-nav-einstellungen-plaene:"\E0A8",
    mi-nav-einstellungen-projekt:"\E0A9",
    mi-nav-einstellungen-spalten:"\E0AA",
    mi-nav-einstellungen-vorschlagswerte:"\E0AB",
    mi-nav-einstellungen:"\E0AC",
    mi-nav-finanzbuchhaltung:"\E0AD",
    mi-nav-kalender:"\E0AE",
    mi-nav-lohnbuchhaltung:"\E0AF",
    mi-nav-messerli:"\E0B0",
    mi-nav-offline:"\E0B1",
    mi-nav-projektmanagement:"\E0B2",
    mi-nav-rapport-abgeschlossen:"\E0B3",
    mi-nav-rapport-adressen:"\E0B4",
    mi-nav-rapport-allgemein:"\E0B5",
    mi-nav-rapport-anhang:"\E0B6",
    mi-nav-rapport-auftragsbearbeitung:"\E0B7",
    mi-nav-rapport-eigenschaften:"\E0B8",
    mi-nav-rapport-inbearbeitung:"\E0B9",
    mi-nav-rapport-material:"\E0BA",
    mi-nav-rapport-pool:"\E0BB",
    mi-nav-rapport-rapport:"\E0BC",
    mi-nav-rapport-userdef:"\E0BD",
    mi-nav-rapport-zeit:"\E0BE",
    mi-nav-rapport-zusammenfassung:"\E0BF",
    mi-nav-zerf-expenses:"\E0C0",
    mi-nav-zerf-saldi:"\E0C1",
    mi-nav-zerf-sonderkosten:"\E0C2",
    mi-nav-zerf-stempel:"\E0C3",
    mi-nav-zerf-time:"\E0C4",
    mi-nav-zerf:"\E0C5",
    mi-navigation-open:"\E0C6",
    mi-navigation-return:"\E0C7",
    mi-neuer-eintrag:"\E0C8",
    mi-notice:"\E0C9",
    mi-offline:"\E0CA",
    mi-online:"\E0CB",
    mi-open-close-left:"\E0CC",
    mi-open-close-right:"\E0CD",
    mi-open-list:"\E0CE",
    mi-paste:"\E0CF",
    mi-pdf-drucken:"\E0D0",
    mi-placeholder-01:"\E0D1",
    mi-placeholder-02:"\E0D2",
    mi-placeholder-03:"\E0D3",
    mi-placeholder-04:"\E0D4",
    mi-placeholder-05:"\E0D5",
    mi-plan-add-marker:"\E0D6",
    mi-plan-administration:"\E0D7",
    mi-plan-marker-done:"\E0D8",
    mi-plan-marker-todo:"\E0D9",
    mi-plan-rectangle-selection-to-move:"\E0DA",
    mi-plan-replace:"\E0DB",
    mi-plan-show-marker:"\E0DC",
    mi-plan:"\E0DD",
    mi-print:"\E0DE",
    mi-printer-1:"\E0DF",
    mi-printer-2:"\E0E0",
    mi-printer-3:"\E0E1",
    mi-radio-btn-selected:"\E0E2",
    mi-radio-btn-unselected:"\E0E3",
    mi-redo:"\E0E4",
    mi-reset:"\E0E5",
    mi-resize-width-containers:"\E0E6",
    mi-saldi:"\E0E7",
    mi-save:"\E0E8",
    mi-screen-large:"\E0E9",
    mi-screen-medium:"\E0EA",
    mi-screen-small:"\E0EB",
    mi-search-invert:"\E0EC",
    mi-search:"\E0ED",
    mi-send:"\E0EE",
    mi-show-complete-element:"\E0EF",
    mi-show-grid:"\E0F0",
    mi-show-hidden-elements:"\E0F1",
    mi-show-tiles:"\E0F2",
    mi-sort-asc-1-horizontal:"\E0F3",
    mi-sort-asc-1:"\E0F4",
    mi-sort-asc-2-horizontal:"\E0F5",
    mi-sort-asc-2:"\E0F6",
    mi-sort-asc-3-horizontal:"\E0F7",
    mi-sort-asc-3:"\E0F8",
    mi-sort-asc-4-horizontal:"\E0F9",
    mi-sort-asc-4:"\E0FA",
    mi-sort-asc-5-horizontal:"\E0FB",
    mi-sort-asc-5:"\E0FC",
    mi-sort-asc:"\E0FD",
    mi-sort-desc-1-horizontal:"\E0FE",
    mi-sort-desc-1:"\E0FF",
    mi-sort-desc-2-horizontal:"\E100",
    mi-sort-desc-2:"\E101",
    mi-sort-desc-3-horizontal:"\E102",
    mi-sort-desc-3:"\E103",
    mi-sort-desc-4-horizontal:"\E104",
    mi-sort-desc-4:"\E105",
    mi-sort-desc-5-horizontal:"\E106",
    mi-sort-desc-5:"\E107",
    mi-sort-desc:"\E108",
    mi-sort-group:"\E109",
    mi-sort-left:"\E10A",
    mi-sort-list-asc:"\E10B",
    mi-sort-list-desc:"\E10C",
    mi-sort-right:"\E10D",
    mi-star-empty:"\E10E",
    mi-star-full:"\E10F",
    mi-stop_circle:"\E110",
    mi-tile-angeordnete-ueberstunden:"\E111",
    mi-tile-arbeitszeit-attendance:"\E112",
    mi-tile-arbeitszeit:"\E113",
    mi-tile-attendance:"\E114",
    mi-tile-expenses:"\E115",
    mi-tile-sollzeit-attendance:"\E116",
    mi-tile-sollzeit:"\E117",
    mi-tile-zeitzuschlag:"\E118",
    mi-time-history:"\E119",
    mi-time-stop-watch:"\E11A",
    mi-time:"\E11B",
    mi-to-left:"\E11C",
    mi-to-right:"\E11D",
    mi-troubleshoot:"\E11E",
    mi-undo:"\E11F",
    mi-version:"\E120",
    mi-weather-cloudy:"\E121",
    mi-weather-fog:"\E122",
    mi-weather-heavy-rain:"\E123",
    mi-weather-heavy-snow:"\E124",
    mi-weather-light-rain:"\E125",
    mi-weather-light-snow:"\E126",
    mi-weather-rain:"\E127",
    mi-weather-slightly-cloudy:"\E128",
    mi-weather-snow:"\E129",
    mi-weather-sunny:"\E12A",
    mi-weather-thunderstorm:"\E12B",
    mi-weather-very-cloudy:"\E12C",
    mi-week:"\E12D",
    mi-weiterleiten:"\E12E",
    mi-zoom-center:"\E12F",
    mi-zoom-in:"\E130",
    mi-zoom-out:"\E131",
);

@font-face {
    font-family: "messerli-icons";
    src: url('messerli-icons.eot?cachebust=1716554083');
    src: url('fonts/messerli-icons/messerli-icons.eot?cachebust=1716554083#iefix') format('eot'), url('fonts/messerli-icons/messerli-icons.woff2?cachebust=1716554083') format('woff2'), url('fonts/messerli-icons/messerli-icons.woff?cachebust=1716554083') format('woff'), url('fonts/messerli-icons/messerli-icons.ttf?cachebust=1716554083') format('truetype'), url('fonts/messerli-icons/messerli-icons.svg?cachebust=1716554083#messerli-icons') format('svg');
}

.mi:before {
    font-family: "messerli-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
    vertical-align: bottom;
}

.mi-abschliessen:before {
	content: "\E001";
}

.mi-add-canvas:before {
	content: "\E002";
}

.mi-add-document:before {
	content: "\E003";
}

.mi-add-new-item:before {
	content: "\E004";
}

.mi-add-photo:before {
	content: "\E005";
}

.mi-add-recording:before {
	content: "\E006";
}

.mi-add-signature:before {
	content: "\E007";
}

.mi-add-smart-entry:before {
	content: "\E008";
}

.mi-add-sollzeit:before {
	content: "\E009";
}

.mi-adr-email:before {
	content: "\E00A";
}

.mi-adr-mobile:before {
	content: "\E00B";
}

.mi-adr-person:before {
	content: "\E00C";
}

.mi-adr-route:before {
	content: "\E00D";
}

.mi-adr-tel:before {
	content: "\E00E";
}

.mi-adr-www:before {
	content: "\E00F";
}

.mi-angestellter:before {
	content: "\E010";
}

.mi-attachment:before {
	content: "\E011";
}

.mi-attendance:before {
	content: "\E012";
}

.mi-audio-pause:before {
	content: "\E013";
}

.mi-audio-play:before {
	content: "\E014";
}

.mi-audio-record-start:before {
	content: "\E015";
}

.mi-audio-stop:before {
	content: "\E016";
}

.mi-auftrag-lager:before {
	content: "\E017";
}

.mi-auftrag-ohne:before {
	content: "\E018";
}

.mi-auftrag-regie:before {
	content: "\E019";
}

.mi-auftrag-sammel:before {
	content: "\E01A";
}

.mi-auftrag-sub-lager:before {
	content: "\E01B";
}

.mi-auftrag-sub-regie:before {
	content: "\E01C";
}

.mi-auftrag-sub-teilleistung-regie:before {
	content: "\E01D";
}

.mi-auftrag-sub-teilleistung:before {
	content: "\E01E";
}

.mi-auftrag-sub-unproduktiv:before {
	content: "\E01F";
}

.mi-auftrag-sub:before {
	content: "\E020";
}

.mi-auftrag-teilleistung-regie:before {
	content: "\E021";
}

.mi-auftrag-teilleistung:before {
	content: "\E022";
}

.mi-auftrag-unproduktiv:before {
	content: "\E023";
}

.mi-auftrag:before {
	content: "\E024";
}

.mi-auftragsgruppe:before {
	content: "\E025";
}

.mi-avatar:before {
	content: "\E026";
}

.mi-avis-mark-send:before {
	content: "\E027";
}

.mi-avis:before {
	content: "\E028";
}

.mi-back-edit:before {
	content: "\E029";
}

.mi-bereich:before {
	content: "\E02A";
}

.mi-buchung-regie:before {
	content: "\E02B";
}

.mi-buchung-teilleistung:before {
	content: "\E02C";
}

.mi-buchung-unproduktiv:before {
	content: "\E02D";
}

.mi-calendar:before {
	content: "\E02E";
}

.mi-cancel:before {
	content: "\E02F";
}

.mi-cb-enabled-indeterminate:before {
	content: "\E030";
}

.mi-cb-enabled-selected:before {
	content: "\E031";
}

.mi-cb-enabled-unselected:before {
	content: "\E032";
}

.mi-check-all:before {
	content: "\E033";
}

.mi-checkmark-circled:before {
	content: "\E034";
}

.mi-circel-left:before {
	content: "\E035";
}

.mi-circel-right:before {
	content: "\E036";
}

.mi-close-circled:before {
	content: "\E037";
}

.mi-close:before {
	content: "\E038";
}

.mi-cloud-download:before {
	content: "\E039";
}

.mi-cloud-upload:before {
	content: "\E03A";
}

.mi-columns-managment:before {
	content: "\E03B";
}

.mi-columns-max-resize:before {
	content: "\E03C";
}

.mi-columns-settings:before {
	content: "\E03D";
}

.mi-copy-fill:before {
	content: "\E03E";
}

.mi-copy:before {
	content: "\E03F";
}

.mi-cost-absenzen:before {
	content: "\E040";
}

.mi-cost-arbeitsstunden-nicht-belastbar:before {
	content: "\E041";
}

.mi-cost-arbeitsstunden-regie:before {
	content: "\E042";
}

.mi-cost-arbeitsstunden-teilleistung-regie:before {
	content: "\E043";
}

.mi-cost-arbeitsstunden-teilleistung:before {
	content: "\E044";
}

.mi-cost-arbeitsstunden:before {
	content: "\E045";
}

.mi-cost-auszahlung:before {
	content: "\E046";
}

.mi-cost-compesnsation:before {
	content: "\E047";
}

.mi-cost-document:before {
	content: "\E048";
}

.mi-cost-eat:before {
	content: "\E049";
}

.mi-cost-ferien:before {
	content: "\E04A";
}

.mi-cost-krankheit:before {
	content: "\E04B";
}

.mi-cost-pause:before {
	content: "\E04C";
}

.mi-cost-sonderkosten:before {
	content: "\E04D";
}

.mi-cost-spesen-nicht-belastbar:before {
	content: "\E04E";
}

.mi-cost-spesen-regie:before {
	content: "\E04F";
}

.mi-cost-spesen-teilleistung-regie:before {
	content: "\E050";
}

.mi-cost-spesen-teilleistung:before {
	content: "\E051";
}

.mi-cost-spesen:before {
	content: "\E052";
}

.mi-cost-umbuchungen:before {
	content: "\E053";
}

.mi-cost-unfall:before {
	content: "\E054";
}

.mi-cut:before {
	content: "\E055";
}

.mi-day:before {
	content: "\E056";
}

.mi-delete:before {
	content: "\E057";
}

.mi-directory-close:before {
	content: "\E058";
}

.mi-directory-open:before {
	content: "\E059";
}

.mi-drag-horizontally:before {
	content: "\E05A";
}

.mi-drag-indicator:before {
	content: "\E05B";
}

.mi-dropdown-arrow:before {
	content: "\E05C";
}

.mi-edit:before {
	content: "\E05D";
}

.mi-email-alert:before {
	content: "\E05E";
}

.mi-email:before {
	content: "\E05F";
}

.mi-erase:before {
	content: "\E060";
}

.mi-erledigt:before {
	content: "\E061";
}

.mi-expenses:before {
	content: "\E062";
}

.mi-external-drive:before {
	content: "\E063";
}

.mi-file-audio:before {
	content: "\E064";
}

.mi-file-excel:before {
	content: "\E065";
}

.mi-file-image:before {
	content: "\E066";
}

.mi-file-movie:before {
	content: "\E067";
}

.mi-file-pdf:before {
	content: "\E068";
}

.mi-file-word:before {
	content: "\E069";
}

.mi-file:before {
	content: "\E06A";
}

.mi-filter:before {
	content: "\E06B";
}

.mi-fix-sidebar-2:before {
	content: "\E06C";
}

.mi-fix-sidebar:before {
	content: "\E06D";
}

.mi-gruppierung:before {
	content: "\E06E";
}

.mi-hard-disk:before {
	content: "\E06F";
}

.mi-holding-firma-filiale:before {
	content: "\E070";
}

.mi-holding-firma:before {
	content: "\E071";
}

.mi-holding:before {
	content: "\E072";
}

.mi-image:before {
	content: "\E073";
}

.mi-info-outlined:before {
	content: "\E074";
}

.mi-info:before {
	content: "\E075";
}

.mi-keine-eintraege:before {
	content: "\E076";
}

.mi-kostenstelle:before {
	content: "\E077";
}

.mi-list-placeholder:before {
	content: "\E078";
}

.mi-list-read:before {
	content: "\E079";
}

.mi-logout:before {
	content: "\E07A";
}

.mi-mail-reply:before {
	content: "\E07B";
}

.mi-masseneingabe:before {
	content: "\E07C";
}

.mi-month:before {
	content: "\E07D";
}

.mi-move-item:before {
	content: "\E07E";
}

.mi-move:before {
	content: "\E07F";
}

.mi-multiple-selection-undo:before {
	content: "\E080";
}

.mi-multiple-selection:before {
	content: "\E081";
}

.mi-nav-adressverwaltung:before {
	content: "\E082";
}

.mi-nav-auftragsbearbeitung:before {
	content: "\E083";
}

.mi-nav-bauadministration:before {
	content: "\E084";
}

.mi-nav-bauleitung-abnahme-objekt:before {
	content: "\E085";
}

.mi-nav-bauleitung-abnahme-werk:before {
	content: "\E086";
}

.mi-nav-bauleitung-abnahme:before {
	content: "\E087";
}

.mi-nav-bauleitung-auftraege:before {
	content: "\E088";
}

.mi-nav-bauleitung-baujournal:before {
	content: "\E089";
}

.mi-nav-bauleitung-besprechungen:before {
	content: "\E08A";
}

.mi-nav-bauleitung-entscheide:before {
	content: "\E08B";
}

.mi-nav-bauleitung-gesamtliste:before {
	content: "\E08C";
}

.mi-nav-bauleitung-maengel:before {
	content: "\E08D";
}

.mi-nav-bauleitung-notizen:before {
	content: "\E08E";
}

.mi-nav-bauleitung-pendenzen:before {
	content: "\E08F";
}

.mi-nav-bauleitung-projektadressen:before {
	content: "\E090";
}

.mi-nav-bauleitung-vorerfassen:before {
	content: "\E091";
}

.mi-nav-bauleitung:before {
	content: "\E092";
}

.mi-nav-crm:before {
	content: "\E093";
}

.mi-nav-demo-account:before {
	content: "\E094";
}

.mi-nav-demo-button:before {
	content: "\E095";
}

.mi-nav-demo-color:before {
	content: "\E096";
}

.mi-nav-demo-components:before {
	content: "\E097";
}

.mi-nav-demo-dynamic-form:before {
	content: "\E098";
}

.mi-nav-demo-elements:before {
	content: "\E099";
}

.mi-nav-demo-file-upload:before {
	content: "\E09A";
}

.mi-nav-demo-form-row:before {
	content: "\E09B";
}

.mi-nav-demo-form:before {
	content: "\E09C";
}

.mi-nav-demo-grid-view-config:before {
	content: "\E09D";
}

.mi-nav-demo-grid-view:before {
	content: "\E09E";
}

.mi-nav-demo-icon:before {
	content: "\E09F";
}

.mi-nav-demo-master-detail-view:before {
	content: "\E0A0";
}

.mi-nav-demo-modal:before {
	content: "\E0A1";
}

.mi-nav-demo-tile-view:before {
	content: "\E0A2";
}

.mi-nav-demo-toolbar:before {
	content: "\E0A3";
}

.mi-nav-demo-typography:before {
	content: "\E0A4";
}

.mi-nav-dokument:before {
	content: "\E0A5";
}

.mi-nav-einstellungen-allgemein:before {
	content: "\E0A6";
}

.mi-nav-einstellungen-farben:before {
	content: "\E0A7";
}

.mi-nav-einstellungen-plaene:before {
	content: "\E0A8";
}

.mi-nav-einstellungen-projekt:before {
	content: "\E0A9";
}

.mi-nav-einstellungen-spalten:before {
	content: "\E0AA";
}

.mi-nav-einstellungen-vorschlagswerte:before {
	content: "\E0AB";
}

.mi-nav-einstellungen:before {
	content: "\E0AC";
}

.mi-nav-finanzbuchhaltung:before {
	content: "\E0AD";
}

.mi-nav-kalender:before {
	content: "\E0AE";
}

.mi-nav-lohnbuchhaltung:before {
	content: "\E0AF";
}

.mi-nav-messerli:before {
	content: "\E0B0";
}

.mi-nav-offline:before {
	content: "\E0B1";
}

.mi-nav-projektmanagement:before {
	content: "\E0B2";
}

.mi-nav-rapport-abgeschlossen:before {
	content: "\E0B3";
}

.mi-nav-rapport-adressen:before {
	content: "\E0B4";
}

.mi-nav-rapport-allgemein:before {
	content: "\E0B5";
}

.mi-nav-rapport-anhang:before {
	content: "\E0B6";
}

.mi-nav-rapport-auftragsbearbeitung:before {
	content: "\E0B7";
}

.mi-nav-rapport-eigenschaften:before {
	content: "\E0B8";
}

.mi-nav-rapport-inbearbeitung:before {
	content: "\E0B9";
}

.mi-nav-rapport-material:before {
	content: "\E0BA";
}

.mi-nav-rapport-pool:before {
	content: "\E0BB";
}

.mi-nav-rapport-rapport:before {
	content: "\E0BC";
}

.mi-nav-rapport-userdef:before {
	content: "\E0BD";
}

.mi-nav-rapport-zeit:before {
	content: "\E0BE";
}

.mi-nav-rapport-zusammenfassung:before {
	content: "\E0BF";
}

.mi-nav-zerf-expenses:before {
	content: "\E0C0";
}

.mi-nav-zerf-saldi:before {
	content: "\E0C1";
}

.mi-nav-zerf-sonderkosten:before {
	content: "\E0C2";
}

.mi-nav-zerf-stempel:before {
	content: "\E0C3";
}

.mi-nav-zerf-time:before {
	content: "\E0C4";
}

.mi-nav-zerf:before {
	content: "\E0C5";
}

.mi-navigation-open:before {
	content: "\E0C6";
}

.mi-navigation-return:before {
	content: "\E0C7";
}

.mi-neuer-eintrag:before {
	content: "\E0C8";
}

.mi-notice:before {
	content: "\E0C9";
}

.mi-offline:before {
	content: "\E0CA";
}

.mi-online:before {
	content: "\E0CB";
}

.mi-open-close-left:before {
	content: "\E0CC";
}

.mi-open-close-right:before {
	content: "\E0CD";
}

.mi-open-list:before {
	content: "\E0CE";
}

.mi-paste:before {
	content: "\E0CF";
}

.mi-pdf-drucken:before {
	content: "\E0D0";
}

.mi-placeholder-01:before {
	content: "\E0D1";
}

.mi-placeholder-02:before {
	content: "\E0D2";
}

.mi-placeholder-03:before {
	content: "\E0D3";
}

.mi-placeholder-04:before {
	content: "\E0D4";
}

.mi-placeholder-05:before {
	content: "\E0D5";
}

.mi-plan-add-marker:before {
	content: "\E0D6";
}

.mi-plan-administration:before {
	content: "\E0D7";
}

.mi-plan-marker-done:before {
	content: "\E0D8";
}

.mi-plan-marker-todo:before {
	content: "\E0D9";
}

.mi-plan-rectangle-selection-to-move:before {
	content: "\E0DA";
}

.mi-plan-replace:before {
	content: "\E0DB";
}

.mi-plan-show-marker:before {
	content: "\E0DC";
}

.mi-plan:before {
	content: "\E0DD";
}

.mi-print:before {
	content: "\E0DE";
}

.mi-printer-1:before {
	content: "\E0DF";
}

.mi-printer-2:before {
	content: "\E0E0";
}

.mi-printer-3:before {
	content: "\E0E1";
}

.mi-radio-btn-selected:before {
	content: "\E0E2";
}

.mi-radio-btn-unselected:before {
	content: "\E0E3";
}

.mi-redo:before {
	content: "\E0E4";
}

.mi-reset:before {
	content: "\E0E5";
}

.mi-resize-width-containers:before {
	content: "\E0E6";
}

.mi-saldi:before {
	content: "\E0E7";
}

.mi-save:before {
	content: "\E0E8";
}

.mi-screen-large:before {
	content: "\E0E9";
}

.mi-screen-medium:before {
	content: "\E0EA";
}

.mi-screen-small:before {
	content: "\E0EB";
}

.mi-search-invert:before {
	content: "\E0EC";
}

.mi-search:before {
	content: "\E0ED";
}

.mi-send:before {
	content: "\E0EE";
}

.mi-show-complete-element:before {
	content: "\E0EF";
}

.mi-show-grid:before {
	content: "\E0F0";
}

.mi-show-hidden-elements:before {
	content: "\E0F1";
}

.mi-show-tiles:before {
	content: "\E0F2";
}

.mi-sort-asc-1-horizontal:before {
	content: "\E0F3";
}

.mi-sort-asc-1:before {
	content: "\E0F4";
}

.mi-sort-asc-2-horizontal:before {
	content: "\E0F5";
}

.mi-sort-asc-2:before {
	content: "\E0F6";
}

.mi-sort-asc-3-horizontal:before {
	content: "\E0F7";
}

.mi-sort-asc-3:before {
	content: "\E0F8";
}

.mi-sort-asc-4-horizontal:before {
	content: "\E0F9";
}

.mi-sort-asc-4:before {
	content: "\E0FA";
}

.mi-sort-asc-5-horizontal:before {
	content: "\E0FB";
}

.mi-sort-asc-5:before {
	content: "\E0FC";
}

.mi-sort-asc:before {
	content: "\E0FD";
}

.mi-sort-desc-1-horizontal:before {
	content: "\E0FE";
}

.mi-sort-desc-1:before {
	content: "\E0FF";
}

.mi-sort-desc-2-horizontal:before {
	content: "\E100";
}

.mi-sort-desc-2:before {
	content: "\E101";
}

.mi-sort-desc-3-horizontal:before {
	content: "\E102";
}

.mi-sort-desc-3:before {
	content: "\E103";
}

.mi-sort-desc-4-horizontal:before {
	content: "\E104";
}

.mi-sort-desc-4:before {
	content: "\E105";
}

.mi-sort-desc-5-horizontal:before {
	content: "\E106";
}

.mi-sort-desc-5:before {
	content: "\E107";
}

.mi-sort-desc:before {
	content: "\E108";
}

.mi-sort-group:before {
	content: "\E109";
}

.mi-sort-left:before {
	content: "\E10A";
}

.mi-sort-list-asc:before {
	content: "\E10B";
}

.mi-sort-list-desc:before {
	content: "\E10C";
}

.mi-sort-right:before {
	content: "\E10D";
}

.mi-star-empty:before {
	content: "\E10E";
}

.mi-star-full:before {
	content: "\E10F";
}

.mi-stop_circle:before {
	content: "\E110";
}

.mi-tile-angeordnete-ueberstunden:before {
	content: "\E111";
}

.mi-tile-arbeitszeit-attendance:before {
	content: "\E112";
}

.mi-tile-arbeitszeit:before {
	content: "\E113";
}

.mi-tile-attendance:before {
	content: "\E114";
}

.mi-tile-expenses:before {
	content: "\E115";
}

.mi-tile-sollzeit-attendance:before {
	content: "\E116";
}

.mi-tile-sollzeit:before {
	content: "\E117";
}

.mi-tile-zeitzuschlag:before {
	content: "\E118";
}

.mi-time-history:before {
	content: "\E119";
}

.mi-time-stop-watch:before {
	content: "\E11A";
}

.mi-time:before {
	content: "\E11B";
}

.mi-to-left:before {
	content: "\E11C";
}

.mi-to-right:before {
	content: "\E11D";
}

.mi-troubleshoot:before {
	content: "\E11E";
}

.mi-undo:before {
	content: "\E11F";
}

.mi-version:before {
	content: "\E120";
}

.mi-weather-cloudy:before {
	content: "\E121";
}

.mi-weather-fog:before {
	content: "\E122";
}

.mi-weather-heavy-rain:before {
	content: "\E123";
}

.mi-weather-heavy-snow:before {
	content: "\E124";
}

.mi-weather-light-rain:before {
	content: "\E125";
}

.mi-weather-light-snow:before {
	content: "\E126";
}

.mi-weather-rain:before {
	content: "\E127";
}

.mi-weather-slightly-cloudy:before {
	content: "\E128";
}

.mi-weather-snow:before {
	content: "\E129";
}

.mi-weather-sunny:before {
	content: "\E12A";
}

.mi-weather-thunderstorm:before {
	content: "\E12B";
}

.mi-weather-very-cloudy:before {
	content: "\E12C";
}

.mi-week:before {
	content: "\E12D";
}

.mi-weiterleiten:before {
	content: "\E12E";
}

.mi-zoom-center:before {
	content: "\E12F";
}

.mi-zoom-in:before {
	content: "\E130";
}

.mi-zoom-out:before {
	content: "\E131";
}

@mixin mi-icon-aliases($name, $aliases) {
    @each $alias in $aliases {
        .#{$alias}:before {
            content: map-get($messerli-icons-codepoints, $name);
        }
    }
}

@mixin mi-icon-override($name, $aliases) {
    @each $alias in $aliases {
        .#{$alias}:before {
            content: map-get($messerli-icons-codepoints, $name) !important;
            font-family: messerli-icons !important;
        }
    }
}