﻿/* button */
button, .btn {
    box-sizing: border-box;
    height: 3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: $layout-radius-small;
    font-size: $typo-font-std;
    padding: 0.6rem 0.9rem;

    i::before {
        margin-right: 0;
        font-size: $typo-font-icon;
    }

    &:disabled {
        cursor: default;
    }

    &:hover:not(:disabled) {
        cursor: pointer;
    }

    &.-primary {
        background-color: var(--toggle-primary-primarydark);
        color: var(--toggle-white-black);
        border: none;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
            filter: brightness(1.2);
            outline: none;
        }

        &:active:not(:disabled) {
            filter: brightness(0.90);
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }

        &:disabled {
            background-color: var(--primary-color-disabled);
            color: var(--primary-text-color-disabled);
        }
    }

    &.-secondary {
        background: none;
        color: var(--primary-text-color);
        border: $global-border-small solid var(--toggle-primary-primarydark);


        &:hover:not(:disabled, :active),
        &:focus:not(:disabled) {
            background-color: rgba($color-primary, 0.1);
            outline: none;
        }

        &:active:not(:disabled) {
            border-color: var(--toggle-primary-primarydark);
            background-color: var(--toggle-primary-primarydark);
            color: var(--toggle-white-black);
            filter: brightness(0.90);
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }

        &:disabled {
            border-color: var(--primary-color-disabled);
            background-color: none;
            color: var(--secondary-text-color-disabled);
        }
    }

    &.-tertiary {
        background: none;
        color: var(--text-color);
        border: $global-border-small solid $color-gray;

        &:hover:not(:disabled) {
            border: $global-border-small solid var(--outline-hover-color);
            background-color: var(--tertiary-hover-color) !important;
        }

        &:focus {
            border: $global-border-small solid var(--outline-hover-color);
            outline: none;
        }

        &:disabled {
            color: var(--text-color-disabled);
            border: $global-border-small solid rgba($color-gray, 0.2);
        }
    }

    &.-tertiary:not(.-toggle) {
        &:active:not(:disabled) {
            color: $color-black;
            background-color: $color-whitedark !important;
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }
    }

    &.-tertiary.-toggle {
        &:active:not(:disabled) {
            color: $color-black;
            background-color: darken($color-whitedark, 5%) !important;
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }

        &.active:not(:hover):not(:active) {
            background-color: var(--button-selected-background);
            color: var(--button-selected-color);
            border: $global-border-small solid var(--button-selected-border);
            z-index: 1;
        }

        &.active:disabled {
            opacity: 0.5;
            background-color: var(--button-selected-background);
            color: var(--button-selected-color);
            border: $global-border-small solid var(--button-selected-border);
            z-index: 1;
        }
    }

    &.-error {
        background-color: $color-error;
        color: $color-white;
        border: none;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
            filter: brightness(1.2);
            outline: none;
        }

        &:active:not(:disabled) {
            filter: brightness(0.90);
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }

        &:disabled {
            background-color: rgba($color-error, 0.2);
            color: var(--text-color-disabled);
        }

        &.-secondary {
            background: none;
            color: var(--toggle-red-whitedark);
            border: $global-border-small solid $color-error;


            &:hover:not(:disabled, :active),
            &:focus:not(:disabled) {
                background-color: var(--background-error-hover);
                outline: none;
            }

            &:active:not(:disabled) {
                border: none;
                background-color: $color-error;
                color: $color-white;
                filter: brightness(0.90);
                -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
                box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            }

            &:disabled {
                border: none;
                background-color: rgba($color-error, 0.2);
                color: var(--text-color-disabled);
            }
        }
    }

    &.-warning {
        background-color: $color-warning;
        color: $color-white;
        border: none;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
            filter: brightness(1.2);
            outline: none;
        }

        &:active:not(:disabled) {
            filter: brightness(0.90);
            -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
        }

        &:disabled {
            background-color: rgba($color-warning, 0.2);
            color: var(--text-color-disabled);
        }

        &.-secondary {
            background: none;
            color: var(--toggle-orange-whitedark);
            border: $global-border-small solid $color-warning;


            &:hover:not(:disabled, :active),
            &:focus:not(:disabled) {
                background-color: var(--background-warning-hover);
                outline: none;
            }

            &:active:not(:disabled) {
                border: none;
                background-color: $color-warning;
                color: $color-white;
                filter: brightness(0.90);
                -webkit-box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
                box-shadow: inset 0px 1px 4px 1px rgba(var(--color-black-rgb),0.4);
            }

            &:disabled {
                border: none;
                background-color: rgba($color-warning, 0.2);
                color: var(--text-color-disabled);
            }
        }
    }


    &.-bar, &.-function {
        background: none;
        border: none;
        color: var(--text-color);

        &:hover,
        &:focus {
            color: var(--toggle-primary-primarydark);
            outline: none;
        }

        &:active {
            color: var(--toggle-primary-primarydark);
        }

        &:disabled {
            color: var(--text-color-disabled);
        }
    }

    &.-bar {
        padding: 0 8px;
    }

    &.-function {
        background-color: var(--field-background);
    }

    &.-link {
        background: none;
        color: var(--primary-text-color);
        border: none;
        font-size: $typo-font-std;
        text-decoration: var(--link-decoration);
        text-underline-offset: 4px;

        &.-text-left {
            text-align: left;
        }


        &:hover:not(:disabled),
        &:focus {
            background-color: var(--link-hover);
            outline: none;
        }

        &:active {
            background-color: var(--link-active);
        }

        &:disabled {
            color: var(--text-color-disabled);
        }
    }

    &.-small {
        height: 2.7rem;
        font-size: $typo-font-small;
        padding: 0.8rem 0.6rem;

        i::before {
            margin-right: 0;
            font-size: $typo-font-icon-small;
        }
    }

    &.-large {
        height: 3.5rem;
        font-size: $typo-font-large;
        padding: 0.9rem 1.2rem;

        i::before {
            margin-right: 0;
            font-size: $typo-font-icon-large;
        }
    }

    &.-small-width {
        width: 30px;
        padding: 0; // Safari
    }

    &.-medium-width {
        min-width: 120px;
    }

    &.-large-width {
        width: 160px;
    }

    &.-larger-width {
        width: 220px;
    }

    &.-huge-width {
        width: 300px;
    }

    &.-full-width {
        width: 100%;
    }

    &.-icon-label, &.-icon {
        font-size: $typo-font-std;
        padding: 0.5rem 0.9rem;

        &.-small {
            padding: 0.5rem 0.6rem;
        }

        &.-large {
            padding: 0.6rem 1.2rem;
        }
    }

    &.-icon-label i::before {
        margin-right: $layout-space-small;
    }
}
