﻿/*some common definitions are defined input _input.scss*/
/*
-Checkbox
    <div class="row-checkbox" >
        <input type="checkbox" id="name" name="name" >
        <label for="name" > Displaytext</label >
    </div >

-Radio
    <div class="row-radio" >
        <input type="radio" id="name" name="name" value="value" >
        <label for="name" > Displaytext</label >
    </div >
*/

.row-radio,
.row-checkbox {
    position: relative;
    display: block;
    align-items: center;
    margin: $layout-space-medium $layout-space-small $layout-space-medium 0;

    &.-large {
        width: 160px;
    }

    &.-larger {
        width: 220px;
    }

    &.-huge {
        width: 300px;
    }

    &.-full-width {
        width: 100%;
    }
}

input[type=checkbox],
input[type=radio] {
    position: absolute;
    opacity: 0;
    width: 1.5rem;
    height: 1.5rem;

    &:disabled + label {
        cursor: default;
    }

    &:not(:disabled) + label {
        cursor: pointer;
    }

    + label {
        line-height: 1.5rem;
    }

    + label::before {
        font-family: "messerli-icons";
        font-size: larger;
        display: inline-block;
        margin-right: $layout-space-small;
        text-align: center;
        vertical-align: text-top;
    }
}

input[type=checkbox] {
    + label::before {
        content: var(--icon-cb-enabled-unselected);
        width: 15px;
        height: 15px;
    }
    // Box checked
    &:checked + label::before {
        content: var(--icon-cb-enabled-selected);
    }
    
    // Indeterminate
    &[indeterminate] {
        &+ label::before{
            content: var(--icon-cd-indeterminate);
        }
    }
    
    // Disabled box.
    &:disabled + label::before {
        content: url("images/icons/cb-disabled-unselected.svg");
    }
    &:disabled:checked + label::before {
        content: url("images/icons/cb-disabled-selected.svg");
    }
    &:disabled[indeterminate] {
        &+ label::before{
            content: var(--icon-cd-disabled-indeterminate);
        }
    }
    
    // Hover and focus
    &:hover:not(:disabled) + label::before,
    &:focus + label::before {
        content: var(--icon-cb-unselected-focus);
    }

    &:hover:checked:not(:disabled) + label::before,
    &:focus:checked + label::before {
        content: var(--icon-cb-selected-focus);
    }

    &:hover[indeterminate]:not(:disabled),
    &:focus:indeterminate, &:focus.indeterminate, &:focus[indeterminate]
    {
        &+ label::before{
            content: var(--icon-cd-indeterminate-focus);
        }
    }
}

input[type=radio] {
    & + label::before {
        content: var(--icon-radio-btn-unselected);
        width: 15px;
        height: 15px;
    }
    // Box checked
    &:checked + label::before {
        content: var(--icon-radio-btn-selected);
    }
    // Disabled box.
    &:disabled + label::before {
        content: url("images/icons/radio-btn-unselected-disabled.svg");
    }

    &:disabled:checked + label::before {
        content: url("images/icons/radio-btn-selected-disabled.svg");
    }

    &:hover:not(:disabled) + label::before,
    &:focus + label::before {
        content: var(--icon-radio-btn-unselected-focus);
    }

    &:hover:checked:not(:disabled) + label::before,
    &:focus:checked + label::before {
        content: var(--icon-radio-btn-selected-focus);
    }
}