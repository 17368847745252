﻿/*some common definitions are defined input _input.scss*/

select {
    background: var(--field-background) var(--icon-dropdown-arrow) no-repeat;
    background-position: right $layout-space-small top 50%;
    background-size: $typo-font-std;
    padding-right: 30px;
    text-overflow: ellipsis;
    // overwrite user-agent-stylesheet
    &:disabled {
        opacity: 1;
    }

    &:disabled:not(.readonly) {
        background: var(--field-background) url("images/icons/dropdown-arrow-disabled.svg") no-repeat;
        background-position: right $layout-space-small top 50%;
        background-size: $typo-font-std;
        cursor: default;
    }

    &:focus, &:hover:not(:disabled) {
        background: var(--field-background) var(--icon-dropdown-arrow-focus) no-repeat;
        background-position: right $layout-space-small top 50%;
        background-size: $typo-font-std;
        cursor: pointer;
    }

    &:hover:not(:disabled) {
        background-color: var(--field-background-hover);
    }

    &:focus {
        background-color: var(--field-background-focus);
    }

    option {
        color: var(--text-color);
        background-color: var(--background-color);
    }

    &.-bar {
        background-color: transparent;
        height: 36px;
        margin-right: 8px;
    }

    &.-tertiary {
        background-color: var(--background-color);
        color: var(--text-color);
        border: $global-border-small solid $color-gray;
        border-radius: 4px;

        &:hover:not(:disabled) {
            border: $global-border-small solid var(--outline-hover-color);
            background-color: var(--tertiary-hover-color) !important;
        }

        &:focus {
            border: $global-border-small solid var(--outline-hover-color);
            outline: none;
            background-color: var(--background-color);
        }

        &:disabled {
            color: var(--text-color-disabled);
            border: $global-border-small solid rgba($color-gray, 0.2);
            background-color: var(--background-color);
        }
    }

    &.-large {
        width: 160px;
    }

    &.-larger {
        width: 220px;
    }

    &.-huge {
        width: 300px;
    }

    &.-full-width {
        width: 100%;
    }
}

// todo
.select-option {

    &:hover, &:active, &:focus {
        background-color: var(--toggle-primary-primarydark);
    }
}
