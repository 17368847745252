﻿@mixin roboto-font-definition-woff2($font-type) {
    src: url('#{$roboto-font-path}/#{$roboto-font-name}-#{$font-type}.woff2') format('woff2');
}

/* Regular */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('regular');
    font-weight: $typo-font-weight-regular;
    font-style: normal;
}

/* Italic */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('italic');
    font-weight: $typo-font-weight-regular;
    font-style: italic;
}

/* Light */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('light');
    font-weight: $typo-font-weight-light;
    font-style: normal;
}

/* Light Italic*/
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('lightital');
    font-weight: $typo-font-weight-light;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('medium');
    font-weight: $typo-font-weight-medium;
    font-style: normal;
}

/* Medium Italic */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('mediumItalic');
    font-weight: $typo-font-weight-medium;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2('bold');
    font-weight: $typo-font-weight-bold;
    font-style: normal;
}

/* Bold Italic */
@font-face {
    font-family: roboto;
    @include roboto-font-definition-woff2 ('boldItalic');
    font-weight: $typo-font-weight-bold;
    font-style: italic;
}