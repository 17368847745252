@import "../variables";

.layout-with-background {
  display: grid;
  height: 100%;

  .content {
    padding: 0 5%;
    width: 90%;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: start;

    &.with-header {
      max-height: calc(100vh - 64px);
    }
  }
}

@mixin dark-mode {
  .layout-with-background {
    --decoration-image: url(images/decoration-dark.png);
    --decoration-image-overlay: linear-gradient(rgba(var(--color-black-rgb), 0.8), rgba(var(--color-black-rgb), 0.8));
  }
}

@mixin light-mode {
  .layout-with-background {
    --decoration-image: url(images/decoration-default.png);
    --decoration-image-overlay: linear-gradient(rgba(var(--color-white-rgb), 0.9), rgba(var(--color-white-rgb), 0.9));
  }
}

@include light-mode;
.dark-color-scheme {
  @include dark-mode;
}

@media (prefers-color-scheme: dark) {
  @include dark-mode;
  
  .light-color-scheme {
    @include light-mode
  }
}

@media (max-width: map-get($breakpoints-max, 'md')) {
  .layout-with-background {
    grid-template-areas: "content";
    background-image: var(--decoration-image-overlay), var(--decoration-image);
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;

    .decoration {
      display: none;
    }
  }
}

@media (min-width: map-get($breakpoints-min, 'lg')) {
  .layout-with-background {
    grid-template-columns: repeat(2,1fr);
    grid-template-areas: "decoration content";

    .decoration {
      grid-area: decoration;
      background-image: var(--decoration-image);
      background-size: cover;
      background-position: 50%;
      height: 100%;
      max-height: 100vh;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      opacity: 0.2;
    }

    .content {
      grid-area: content;
    }
  }
}
