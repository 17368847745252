.PinturaRoot{
  input[type=radio] {
    & + label::before,
    // Box checked
    &:checked + label::before,
    // Disabled box.
    &:disabled + label::before,
    &:disabled:checked + label::before,
    &:hover:not(:disabled) + label::before,
    &:focus + label::before,
    &:hover:checked:not(:disabled) + label::before,
    &:focus:checked + label::before {
      all: unset;
    }
  }
}